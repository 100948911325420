import React from 'react'
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import SEO from 'components/elements/SEO/SEO'

const FAQCapri = () => {
  const { t } = useTranslation()

  return (
    <>
      <SEO title={t('faqCapri.title')} />
      <div className="faq-container closer">
        <h1>
          <Trans>{t('faqCapri.title')}</Trans>
        </h1>
        <h4>
          <Trans>{t('faqCapri.quest1')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqCapri.ans1')}</Trans>
          <u>
            <a href="mailto:info@pompeiify.com">email</a>
          </u>
          <Trans>{t('faqCapri.ans1-1')}</Trans>
        </p>
        <h4>
          <Trans>{t('faqCapri.quest2')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqCapri.ans2')}</Trans>
        </p>
        <h4>
          <Trans>{t('faqCapri.quest3')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqCapri.ans3')}</Trans>
        </p>
        <h4>
          <Trans>{t('faqCapri.quest4')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqCapri.ans4')}</Trans>
        </p>
        <h4>
          <Trans>{t('faqCapri.quest5')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqCapri.ans5')}</Trans>
        </p>
        <h4>
          <Trans>{t('faqCapri.quest6')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqCapri.ans6')}</Trans>
        </p>
        <h4>
          <Trans>{t('faqCapri.quest7')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqCapri.ans7')}</Trans>
        </p>
        <h4>
          <Trans>{t('faqCapri.quest8')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqCapri.ans8')}</Trans>
          <u>
            <a href="mailto:info@pompeiify.com">email</a>
          </u>
          <Trans>{t('faqCapri.ans8-1')}</Trans>
        </p>
        <h4>
          <Trans>{t('faqCapri.quest9')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqCapri.ans9')}</Trans>
        </p>
        <h4>
          <Trans>{t('faqCapri.quest10')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqCapri.ans10')}</Trans>
          <u>
            <a href="mailto:info@pompeiify.com">email</a>
          </u>
          <Trans>{t('faqCapri.ans10-1')}</Trans>
        </p>
        <h4>
          <Trans>{t('faqCapri.quest11')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqCapri.ans11')}</Trans>
        </p>
        <h4>
          <Trans>{t('faq.quest4')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqErcolano.ans4')}</Trans>
        </p>
        <h4>
          <Trans>{t('faqCapri.quest13')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqCapri.ans13')}</Trans>
        </p>
        <h4>
          <Trans>{t('faqCapri.quest14')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqCapri.ans14')}</Trans>
        </p>
        <h4>
          <Trans>{t('faqCapri.quest15')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqCapri.ans15')}</Trans>
        </p>
        <h4>
          <Trans>{t('faqCapri.quest16')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqCapri.ans16')}</Trans>
          <u>
            <a href="mailto:info@pompeiify.com">email</a>
          </u>
          <Trans>{t('faqCapri.ans16-1')}</Trans>
        </p>
        <h4>
          <Trans>{t('faqCapri.quest17')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqCapri.ans17')}</Trans>
        </p>
        <h4>
          <Trans>{t('faqCapri.quest18')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqCapri.ans18')}</Trans>
        </p>
        <h4>
          <Trans>{t('faqCapri.quest19')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqCapri.ans19')}</Trans>
        </p>
        <h4>
          <Trans>{t('faq.quest2')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqErcolano.ans2')}</Trans>
          <u>
            <Link to="/cancel-policy">
              <Trans>{t('cancelPolicy.title')}</Trans>
            </Link>
          </u>
          <Trans>{t('faqCapri.ans20')}</Trans>
        </p>
      </div>
    </>
  )
}
export default FAQCapri
